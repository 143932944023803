/* eslint-disable arrow-body-style */
import React from 'react'
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'

// Components
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled from 'styled-components'

const StyledImg = styled(Plaatjie)`
  transition: 0.25s;
`

const ImgContainer = styled.div`
  overflow: hidden;
`

const ProjectContent = styled.div`
  bottom: 40px;
  left: 15px;
  background-color: rgba(70, 75, 62, 0.83);
  padding: 10px 40px;
  z-index: 3 h2 {
    z-index: 4;
    opacity: 1;
  }
`

const StyledSingleProject = styled(CustomLink)`
  &:hover {
    ${StyledImg} {
      transform: scale(1.05);
    }
  }

  height: 200px;
  overflow: hidden;
  margin: 10px 0;

  @media screen and (max-width: 767px) {
  }
`

const SingleService = ({ className, service }) => {
  if (!service.image.localFile) {
    return null
  }

  return (
    <StyledSingleProject
      to={service.link.url}
      className={`position-relative ${className ? `${className}` : ``}`}
    >
      <ImgContainer>
        <StyledImg image={service.image} alt="" />
      </ImgContainer>
      <ProjectContent className="position-absolute">
        <h2 className="mb-0 color-text-light font-size-xm">
          <ParseContent content={service.link.title} />
        </h2>
      </ProjectContent>
    </StyledSingleProject>
  )
}

export default SingleService
