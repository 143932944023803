/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import SingleService from 'components/services/SingleService'

// Third Party
import styled from 'styled-components'

const StyledProjecten = styled.div``

const Services = ({ className, services }) => {

  return (
    <StyledProjecten className={`${className ? `${className}` : ``}`}>
      <div className="row">
        {services.map((service, index) => (
          <SingleService className="col-lg-6 pb-4" service={service} key={index} />
        ))}
      </div>
    </StyledProjecten>
  )
}

export default Services