/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/elements/Hero'
import KoelMonteurs from 'components/shared/before-footer/Koelmonteurs'
import Services from 'components/services/Services'
import ParseContent from 'components/shared/ParseContent'

const ServicesTemplate = ({
  data: {
    page: {
      uri,
      seo,
      pageServices
    },
  },
}) => {
  return (
    <Layout>
      {seo && (
        <SEO seo={seo} socialImage={pageServices.servicebanner.image.localFile.childImageSharp.meta.src} />
      )}

      <Hero src={pageServices.servicebanner.image} title={pageServices.servicebanner.title} />

      <div className="container mb-5 py-5">
        <div className="row">
          <div className="col-lg-6 pl-lg-5">
            <ParseContent content={pageServices.meta.description} />
          </div>
        </div>
        <div className="row mt-lg-5 mt-4">
          <div className="col-lg-12 pl-lg-5">
            <Services services={pageServices.services} />
          </div>
        </div>
      </div>
      <div className="py-lg-5 py-0" />
      <KoelMonteurs />
    </Layout>
  )
}

export default ServicesTemplate

export const pageQuery = graphql`
  query($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      pageServices {
        servicebanner {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        meta {
          description
        }

        services {
          link {
            title
            url
            target
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600, quality: 100)
              }
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`
